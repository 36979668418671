<template>
  <div class="sw-mt-7">
    <div v-if="!isLoaded" class="text-center">
      <v-btn
        rounded
        large
        depressed
        class="
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        disabled
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.loadingRegistration") }}
        </span>
      </v-btn>
    </div>
    <transition name="fade" mode="out-in">
      <div class="signup" v-if="isLoaded && plugin">
        <v-row
          v-if="isComponentVisible"
          no-gutters
          style="flex-direction: column; align-items: flex-start"
          :style="minHeight"
        >
          <v-col cols="12" v-if="plugin.attributes.signup_headline">
            <div class="sw-block-title text-center">
              {{ plugin.attributes.signup_headline }}
            </div>
          </v-col>
          <v-col v-if="!isFormVisible" cols="12">
            <template
              v-if="plugin.attributes && plugin.attributes.signup_intro_content"
            >
              <v-card
                v-if="plugin.attributes.signup_intro_content"
                flat
                width="420"
                class="mx-auto text-center sw-pb-3"
              >
                <div v-html="plugin.attributes.signup_intro_content"></div>
              </v-card>
            </template>
            <div class="text-center">
              <v-btn
                rounded
                large
                depressed
                :style="{ backgroundColor: gmSecondaryColor }"
                class="
                  text-none
                  white--text
                  sw-rounded
                  sw-height-48px
                "
                :loading="isLoading"
                @click="startRegistration"
              >
                <span class="sw-px-2">
                  {{ startRegistrationButtonText }}
                </span>
              </v-btn>
            </div>
          </v-col>
          <template v-if="isFormVisible">
            <v-col cols="12">
              <v-window v-model="window" touchless>
                <v-window-item
                  v-for="(item, i) in windowItems"
                  :key="`content-${i}`"
                  :value="i"
                >
                  <component
                    :is="item.component"
                    @registered="getSignupProgress"
                    :loading="isLoading"
                  />
                </v-window-item>
              </v-window>
            </v-col>
          </template>
        </v-row>
        <v-row
          v-if="!isRegistrationAvailable && plugin.attributes"
          no-gutters
          style="flex-direction: column; align-items: flex-start"
          :style="minHeight"
        >
          <v-col cols="12">
            <div
              v-html="plugin.attributes.signup_closed_content"
              class="text-center"
            ></div>
          </v-col>
        </v-row>
      </div>
    </transition>
  </div>
</template>

<script>
import EventBus from "@/utils/eventBus";
import moment from "moment";
import Verification from "@/components/Signup/Verification.vue";
import Registration from "@/components/Signup/Registration.vue";
import Authorization from "@/components/Signup/Authorization.vue";
import Questions from "@/components/Signup/Questions.vue";
import QuestionsGrouped from "@/components/Signup/QuestionsGrouped.vue";
import Overview from "@/components/Signup/Overview.vue";
import PaymentStripe from "@/components/Signup/PaymentStripe.vue";
import Done from "@/components/Signup/Done.vue";

export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  data: () => ({
    isLoading: false,
    isLoaded: false,
    isFormVisible: false,
    isFormInProgress: false,
    isCompleted: false,
  }),
  computed: {
    user() {
      return this.$store?.getters?.user;
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    plugin() {
      return this.$store?.getters["signup/plugin"];
    },
    category() {
      return this.$store?.getters["signup/category"];
    },
    signupQuery() {
      return this.$store?.getters?.signupQuery;
    },
    attributeTagsEnabled() {
      return this.plugin.attributes?.signup_guests_attribute_tags;
    },
    isRegistrationAvailable() {
      if (!this.plugin?.id) return false;

      return (
        moment(this.plugin?.attributes?.signup_active_from).isSameOrBefore(
          moment(),
        ) &&
        moment(this.plugin?.attributes?.signup_active_till).isSameOrAfter(
          moment(),
        )
      );
    },
    isComponentVisible() {
      return (
        (this.isRegistrationAvailable && !this.isCompleted) ||
        this.isFormInProgress
      );
    },
    startRegistrationButtonText() {
      return (
        this.plugin?.attributes?.signup_start_registration_button ||
        this.$vuetify.lang.t("$vuetify.startRegistration")
      );
    },
    windowItems() {
      return [
        {
          component: Verification,
          enabled: true,
        },
        {
          component: Registration,
          enabled: true,
        },
        {
          component: Authorization,
          enabled: true,
        },
        {
          component: this.attributeTagsEnabled ? QuestionsGrouped : Questions,
          enabled: true,
        },
        {
          component: Overview,
          enabled: true,
        },
        {
          component: PaymentStripe,
          enabled: true,
        },
        {
          component: Done,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store.commit("signup/SET_WINDOW", val);
      },
    },
    windowHeight() {
      return this.$store?.getters?.windowHeight;
    },
    minHeight() {
      return this.isFormVisible ? "795px" : "";
    },
    routeQuery() {
      return this.$route.query;
    },
  },
  watch: {
    window(newVal, oldVal) {
      if (!this.isRegistrationAvailable) {
        return;
      }

      if (newVal === 1 && oldVal === 0) {
        this.isFormVisible = true;
        this.isFormInProgress = true;
      }

      if (newVal === 6 && oldVal === 0) {
        return;
      }

      if (this.isFormVisible) {
        this.$nextTick(() => this.scrollToEl("#registration-anchor", 65));
      }
    },
    routeQuery: {
      immediate: true,
      handler(val) {
        if (Object.keys(val).length === 0) {
          return null;
        }

        const newQuery = { ...val };

        if (val.session_id) {
          delete newQuery.session_id;

          this.$router.replace({ query: newQuery }).catch(() => {});

          this.confirmStripePayment(val.session_id);
        }

        if (val.section === "signup") {
          delete newQuery.section;

          setTimeout(() => {
            this.$nextTick(() => {
              this.scrollToEl("#registration-anchor", 65);
            });
          }, 300);
        }

        this.$router.replace({ query: newQuery }).catch(() => {});
      },
    },
    appLanguage: {
      handler() {
        this.reFetch();
      },
    },
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    async confirmStripePayment(session_id) {
      try {
        const params = {
          checkout_session_id: session_id,
        };

        this.isLoading = true;

        await this.$http.post(
          `/groups/${this.groupId}/payments/stripe/confirm_payment`,
          params,
        );

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;

          EventBus.$emit("showNotification", {
            type: "error",
            message: this.$vuetify.lang.t("$vuetify.paymentError"),
          });

          this.isFormVisible = true;
          this.isFormInProgress = true;
          this.window = 5;

          return null;
        }
      }
    },
    startRegistration() {
      this.isFormVisible = true;
      this.isFormInProgress = true;

      if (!this.user?.id) {
        this.window = 0;
      } else {
        this.window = 1;
      }

      this.$nextTick(() => this.scrollToEl("#registration-anchor", 65));
    },
    async getPluginSilent() {
      const params = {
        prefix: "signup",
        with_attributes: 1,
        lang: this.appLanguage,
        per_page: 1,
      };

      const response = await this.$http.get(`/groups/${this.groupId}/plugins`, {
        params,
      });

      const plugin = response.data.data?.[0];

      this.$store.commit("signup/SET_PLUGIN", plugin);
    },
    async listAttributeTagsSilent() {
      if (!this.plugin?.id) return;

      const params = {
        group_plugin_id: this.plugin.id,
        lang: this.appLanguage,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/tags`,
        { params },
      );

      const tags = response.data.data;

      this.$store.commit("signup/SET_ATTRIBUTE_TAGS", tags);
    },
    async getSignupProgressSilent() {
      if (!this.plugin?.id) return;

      const usersAttrCategoriesParams = {
        group_plugin_id: this.plugin.id,
        per_page: 1,
      };

      const usersAttrCategoriesResponse = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/categories`,
        { params: usersAttrCategoriesParams },
      );

      const category = usersAttrCategoriesResponse.data.data?.[0];

      this.$store.commit("signup/SET_CATEGORY", category);

      const userAttributesNextParams = {
        only_empty: 1,
        category_id: this.category.id,
      };

      const userAttributesNextResponse = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/next`,
        { params: userAttributesNextParams },
      );

      this.isCompleted =
        userAttributesNextResponse.data.meta?.[
          `reserved_g${this.category.id}_completed`
        ];

      if (this.isCompleted) {
        this.isFormVisible = true;
        this.isFormInProgress = true;
        this.window = 6;
        return;
      }

      if (!this.isLoaded) return;

      const haveNextQuestion = !!userAttributesNextResponse.data.data.id;
      let haveSelectedAnswer = false;

      if (this.signupQuery && Object.keys(this.signupQuery).length) {
        const meta = userAttributesNextResponse?.data?.meta;
        const queryKeys = Object.keys(this.signupQuery) || [];

        if (queryKeys[0] in meta) {
          haveSelectedAnswer = true;
        }
      }

      if (
        !!(this.isFormVisible && this.isFormInProgress) &&
        (haveNextQuestion || haveSelectedAnswer)
      ) {
        this.window = 3;
        return;
      }

      this.window = 4;
    },
    async getSignupProgress() {
      try {
        this.isLoading = true;

        await this.getSignupProgressSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getPluginSilent();
        await this.listAttributeTagsSilent();

        if (this.user?.id) {
          await this.getSignupProgressSilent();
        }

        this.isLoaded = true;
        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async reFetch() {
      try {
        this.isLoading = true;

        await this.getPluginSilent();
        await this.listAttributeTagsSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-row no-gutters class="flex-wrap flex-column align-center">
    <v-col cols="12">
      <div class="sw-block-subtitle text-center">
        {{ $vuetify.lang.t("$vuetify.addPaymentInformation") }}
      </div>
    </v-col>
    <v-col cols="12" class="sw-mb-5">
      <div id="checkout"></div>
    </v-col>
    <v-col cols="12" class="text-center">
      <v-btn
        rounded
        large
        depressed
        class="
          sw-mx-1
          text-none
          sw-rounded
          sw-height-48px
        "
        @click="window = 4"
        :loading="isLoading"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.back") }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import EventBus from "@/utils/eventBus";
import { loadStripe } from "@stripe/stripe-js";

export default {
  data: () => ({
    isLoading: false,
    stripe: null,
    checkoutSessionId: null,
    checkout: null,
  }),
  computed: {
    stripePublishableKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },
    user() {
      return this.$store?.getters?.user;
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    plugin() {
      return this.$store?.getters["signup/plugin"];
    },
    category() {
      return this.$store?.getters["signup/category"];
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store?.commit("signup/SET_WINDOW", val);
      },
    },
  },
  watch: {
    window: {
      immediate: true,
      async handler(val) {
        this.destroyCheckout();

        if (val !== 5) {
          return;
        }

        this.initStripe();
      },
    },
    appLanguage: {
      immediate: false,
      async handler() {
        if (this.window !== 5) {
          return;
        }

        this.destroyCheckout();
        this.initStripe();
      },
    },
  },
  methods: {
    async initStripe() {
      try {
        this.isLoading = true;

        const response = await this.$http.get(
          `/groups/${this.groupId}/payments/stripe/fetch_account`,
        );

        const stripeAccount = response.data.data;

        const isPaymentsEnabled =
          stripeAccount.details_submitted && stripeAccount.charges_enabled;

        if (!isPaymentsEnabled) {
          EventBus.$emit("showNotification", {
            type: "error",
            message: this.$vuetify.lang.t("$vuetify.stripeAccountError"),
          });

          this.isLoading = false;
          this.window = 4;

          return null;
        }

        this.stripe = await loadStripe(this.stripePublishableKey, {
          stripeAccount: stripeAccount.id,
        });

        await this.mountEmbeddedCheckout();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;

          EventBus.$emit("showNotification", {
            type: "error",
            message: this.$vuetify.lang.t("$vuetify.paymentError"),
          });
        }
      }
    },
    async fetchClientSecret() {
      try {
        if (!this.category?.id) {
          return null;
        }

        const intentionParams = {
          reason: `purchase:Category,${this.category.id}`,
          locale: this.appLanguage,
        };

        this.isLoading = true;

        const response = await this.$http.post(
          `/groups/${this.groupId}/payments/stripe/create_checkout_session`,
          intentionParams,
        );

        this.isLoading = false;

        this.checkoutSessionId = response.data.data.id;

        return response.data.data.client_secret;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.window = 4;
        }
      }
    },
    async confirmStripePayment() {
      try {
        const params = {
          checkout_session_id: this.checkoutSessionId,
        };

        this.isLoading = true;

        await this.$http.post(
          `/groups/${this.groupId}/payments/stripe/confirm_payment`,
          params,
        );

        this.isLoading = false;

        this.window = 6;
      } catch (error) {
        if (error) {
          this.isLoading = false;

          EventBus.$emit("showNotification", {
            type: "error",
            message: this.$vuetify.lang.t("$vuetify.paymentError"),
          });

          this.window = 5;
        }
      }
    },
    async mountEmbeddedCheckout() {
      this.checkout = await this.stripe.initEmbeddedCheckout({
        fetchClientSecret: this.fetchClientSecret,
        onComplete: this.confirmStripePayment,
      });

      this.checkout.mount("#checkout");
    },
    destroyCheckout() {
      if (this.checkout) {
        this.checkout.destroy();
        this.checkout = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
